import React, {
  useState
} from "react"
import {
  motion
} from "framer-motion"
import tw, {
  css,
  styled
} from "twin.macro"
import {
  Container,
  ContentWithPaddingXl
} from "../../misc/Layouts.js"
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../../misc/Headings.js"
import {
  SectionDescription
} from "../../misc/Typography.js"
//import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg"
import feather from "../../util/feather"

const PrimaryBackgroundContainer = tw(
  Container
)
`-mx-8 px-8 bg-secondary-200 text-gray-700`

const HeadingContainer = tw.div ``
const Subheading = tw(SubheadingBase)
`text-center text-primary-600 mb-4`
const Heading = tw(SectionHeading)
``
const Description = tw(SectionDescription)
`mx-auto text-center text-primary-600`

const FaqsContainer = tw.div `mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`
const FaqsColumn = tw.div `w-full lg:max-w-lg lg:mr-12 last:mr-0`
const Faq = tw.div `select-none cursor-pointer border-b-2 border-primary-300 hover:border-primary-500 transition-colors duration-300 py-6`
const Question = tw.div `flex justify-between items-center`
const QuestionText = tw.div `text-sm sm:text-lg font-semibold tracking-wide`
const QuestionToggleIcon = styled(motion.span)
`
  ${tw`ml-2 transition duration-700`}
  svg {
    ${tw`w-6 h-6`}
  }
`
const Answer = tw(motion.div)
`hidden text-sm font-normal mt-4 text-gray-600`

function FaqComponent({
  subheading = "",
  heading = "Frequently Asked Questions",
  description = "",
  faqs = [{
      question: "Can we pay for the order through the app?",
      answer: "Currently we have not incorporated payment option in the application. But we have it in our plan for the future release.",
    },
    {
      question: "How do we pay for our order?",
      answer: "Once the order is ready, when you reach the pick-up location you pay to the farmer directly.",
    },
    {
      question: "I am a farmer. How can I onboard myself to JUT?",
      answer: "Please send an email to jutt.farm@gmail.com, our onboarding team will reach out to you with the steps for onboarding.",
    },
    {
      question: "Can we pick up all items of my order at one place?",
      answer: "To increase availability and variety of a product our application allows multiple farmers to sell the same produce. The pick location may be the same or different, depending on the farmer that you choose to buy the products from. You can always verify the pickup location to make sure that they are the same or nearby for your convenience.  Our app also gives us a link for the driving directions for that pickup location.",
    },
    {
      question: "How will I know when the order is ready for pick up?      ",
      answer: "JUT app gives a popup notification when the order is ready to pick up. It also tracks various status of the order which you can view in the app.        ",
    },
    {
      question: "Can we cancel an order once placed?",
      answer: "Yes, you can cancel until the farmer packs it and ready for pick up.",
    },
  ],
}) {
  const faqCol1 = []
  const faqCol2 = []
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null)

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null)
    else setActiveQuestionIndex(questionIndex)
  }
  const svgStyle = css `
  w-6 h-6
  `
  faqs.map((faq, index) => {
    const renderedFaq = ( <
      Faq key = {
        index
      }
      onClick = {
        () => toggleQuestion(index)
      } >
      <
      Question >
      <
      QuestionText > {
        faq.question
      } < /QuestionText> <
      QuestionToggleIcon variants = {
        {
          collapsed: {
            rotate: 0
          },
          open: {
            rotate: -180
          },
        }
      }
      initial = "collapsed"
      animate = {
        activeQuestionIndex === index ? "open" : "collapsed"
      }
      transition = {
        {
          duration: 0.02,
          ease: [0.04, 0.62, 0.23, 0.98]
        }
      } >
      {
        /* <ChevronDownIcon /> */ } {
        feather("chevron-down", svgStyle)
      } <
      /QuestionToggleIcon> <
      /Question> <
      Answer variants = {
        {
          open: {
            opacity: 1,
            height: "auto",
            marginTop: "16px",
            display: "block",
          },
          collapsed: {
            opacity: 0,
            height: 0,
            marginTop: "0px",
            display: "none",
          },
        }
      }
      initial = "collapsed"
      animate = {
        activeQuestionIndex === index ? "open" : "collapsed"
      }
      transition = {
        {
          duration: 0.3,
          ease: [0.04, 0.62, 0.23, 0.98]
        }
      } >
      {
        faq.answer
      } <
      /Answer> <
      /Faq>
    )

    if (index % 2 === 0) faqCol1.push(renderedFaq)
    else faqCol2.push(renderedFaq)

    return null
  })
  return ( <
    PrimaryBackgroundContainer >
    <
    ContentWithPaddingXl >
    <
    HeadingContainer > {
      subheading && < Subheading > {
        subheading
      } < /Subheading>} <
      Heading > {
        heading
      } < /Heading> <
      Description > {
        description
      } < /Description> <
      /HeadingContainer> <
      FaqsContainer >
      <
      FaqsColumn > {
        faqCol1
      } < /FaqsColumn> <
      FaqsColumn > {
        faqCol2
      } < /FaqsColumn> <
      /FaqsContainer> <
      /ContentWithPaddingXl> <
      /PrimaryBackgroundContainer>
    )
  }

  export default FaqComponent