import React from "react"
import Layout from "../layout/layout"
import FaqComponent from "../components/faq/FaqComponent"
function FaqPage() {
  return (
    <Layout>
      <FaqComponent />
    </Layout>
  )
}

export default FaqPage
